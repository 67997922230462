import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Multimedia/Image/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/MultimediaPerm';
import UpdateBox from 'components/Web_User_Interface/1440p_Series/Multimedia/Image/PrimaryBoxUpdates';
import ImageTable from 'components/Web_User_Interface/1440p_Series/Multimedia/Image/imageTable';
import ImageAdvancedTable from 'components/Web_User_Interface/1440p_Series/Multimedia/Image/imageAdvancedTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Multimedia Menu // Image Settings",
  "path": "/Web_User_Interface/1440p_Series/Multimedia/Image/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Here you can adjust the image parameters brightness, saturation, contrast, vibrancy and sharpness. You can flip and mirror the video image and dig in deep into the advanced image settings.",
  "image": "./WebUI_1440p_SearchThumb_Multimedia_Image.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Multimedia_Image.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='Multimedia Menu // Image Settings' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Here you can adjust the image parameters brightness, saturation, contrast, vibrancy and sharpness. You can flip and mirror the video image and dig in deep into the advanced image settings.' image='/images/Search/WebUI_1440p_SearchThumb_Multimedia_Image.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Multimedia_Image.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Multimedia/Bild/' locationFR='/fr/Web_User_Interface/1440p_Series/Multimedia/Image/' crumbLabel="Image Settings" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "multimedia-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#multimedia-menu",
        "aria-label": "multimedia menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Multimedia Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/adcd198389798e3127dfceef19cc4ba4/abe2a/1440p_Settings_Multimedia_Image.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACZ0lEQVQoz22T204TYRRGeQsTTGgiTpMeKAHBlrYznZkWSunxn5l/pp2eaCmoKAYDRcVqjAS81Qfw0ihcG19vmQ4iJnrxZd+trHx775lEIkE8Hie+kCCi3CPjHlF994PS+BvF8SWFo+9B1o8vgxRPrmi8uUKe/STrnaCmk8QXFpidnSUUCjETjUYJEouhzN/DrPfojT/hHZzjHlzgPL1APvtIbfc95Z23bA0nlAeniMdnbLqPSKfSJB+mWUwss7S0wkwsFuMmSjhMTk1jizq1ahmrVkJUNhCVIut6Bj2zipp6QCa5TC69QqW0hZ4roqkmhr6OmjWYiUQi3FjO31fYLOT4OrG4nNTYawuqDRvPa9LfHtDp9nGki+s1saXErnts5kuYhhFAM2ntGnhtGEVRFExDZ39b8qRr0ZQWDSGQ0qXXH7E7FNQdC1Hx8es2256k1yqSN3OoWZNMJndrOIWGw2F03aDpd7EcDyEsLEsghKBcrlItbeK0RnQagm6lzqC9xaCjYeo6umagZrX/AXXavo+UTgCyrN/Q6bQlrt+n1RvScCTHe10OOmU0VaWY19FU7XbLwVIUJTDsdDq4rvsHOIXJWpV2q4Ls79LpPsd3JOeHL/i8PySbTZPXjesObzY8hV4DdfzAUGLbVgCd9ujWynjWBjXbpmo1cb0Wp+MjvrwcYZVyaJqJmtX/BZqmGRg2m80gjpRIx8bp7mLvjPHbIw4bdSb9AW69hplcZHUpTiQSIxKJ/nXY0WjQYSqVolAoBKaGYWCY0xvTMMUO+Z0PVMWIV8k1XmdyrC0tc2f2LnNzoeBLpp/yCxvmlrFHylYjAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/adcd198389798e3127dfceef19cc4ba4/e4706/1440p_Settings_Multimedia_Image.avif 230w", "/en/static/adcd198389798e3127dfceef19cc4ba4/d1af7/1440p_Settings_Multimedia_Image.avif 460w", "/en/static/adcd198389798e3127dfceef19cc4ba4/7f308/1440p_Settings_Multimedia_Image.avif 920w", "/en/static/adcd198389798e3127dfceef19cc4ba4/85e95/1440p_Settings_Multimedia_Image.avif 1067w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/adcd198389798e3127dfceef19cc4ba4/a0b58/1440p_Settings_Multimedia_Image.webp 230w", "/en/static/adcd198389798e3127dfceef19cc4ba4/bc10c/1440p_Settings_Multimedia_Image.webp 460w", "/en/static/adcd198389798e3127dfceef19cc4ba4/966d8/1440p_Settings_Multimedia_Image.webp 920w", "/en/static/adcd198389798e3127dfceef19cc4ba4/d7218/1440p_Settings_Multimedia_Image.webp 1067w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/adcd198389798e3127dfceef19cc4ba4/81c8e/1440p_Settings_Multimedia_Image.png 230w", "/en/static/adcd198389798e3127dfceef19cc4ba4/08a84/1440p_Settings_Multimedia_Image.png 460w", "/en/static/adcd198389798e3127dfceef19cc4ba4/c0255/1440p_Settings_Multimedia_Image.png 920w", "/en/static/adcd198389798e3127dfceef19cc4ba4/abe2a/1440p_Settings_Multimedia_Image.png 1067w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/adcd198389798e3127dfceef19cc4ba4/c0255/1440p_Settings_Multimedia_Image.png",
              "alt": "Web User Interface - 1440p Series - Multimedia Image",
              "title": "Web User Interface - 1440p Series - Multimedia Image",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ImageTable mdxType="ImageTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <EuiSpacer mdxType="EuiSpacer" />
    <UpdateBox mdxType="UpdateBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d13115b7146bc2c449f8494ff6aff85f/aa61c/1440p_Settings_Multimedia_Image_Advanced.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACpklEQVQ4y22RS08TURiG+Q9GFyKUVtukLeBdqJ0yMC3ttB2mncuZOdNpRaHeRU0wRKuNt4WXlXHjD3ABxLgyrnSn/gd3/pTHdChCDIsvJzmLJ8/7viPT09NMTk4yOTVFOplADR+z1P9CbWOT8oMtiuublNY3WXywRWl9C31jm0ZvG/HyKznnPqoySzqT4ejoUWITMUYymQzRZbMkTyRYdK/S2XiPf/cVYu017vDMa88xus+od59irD5B3H1D1b9BbmaWc+dmOTl9mlOnzjKSzWbZvWQqxcJcHuE0MWo6jXoZy6jQrJcpawoLygWUmTPkL5ymoinUyxVquk+l3GB+rkRBWWAknU6za3kimURf1PjYs/jUN+m2mpiWwPd9XOERtEKurKwiZYDl2LhLgrC6SkO3KWrzFBR1BziwGwCTySTFokb3kmQlFPiei2VZ2I6LGywTSJ92O6QVhlhCEjqCN5db3LmyRFVXyV9U9wwH0FQqhaZpyKCF43q4QmDbdgQVQmA0HCzbxvMlTmsF4Zi8e3mTe9dLVBZVlPzcnuF+YKfTQUoZgSJD28a2LZqOT8O7hGU10fUKDx895Pevb7xek8yrCsX5wr6Vh5EjQylxHGcI2jGsmTbVag3DqGM1LXRdp/+kx5+fP/j8tIeuKaiFwt7K/wN3Qf/eYQWeJ2iFHUzR5tG9NX7//M722z63XA1lMMpBwEHkQWf7I0vfj/6E6yK8QYer2KaBb1TQ8+dRzk9FjAMjB0EQRd5vuGQYUXcDcNhZptO9TS6X49DhI4yOjXNsLEYsFtsx3F05AhaLtNvtPcNhj0GrTRB2kL7Edz2EXEadKxAbH+d4IkE8Ho/uwMie5/2LGwGbJv7tF3j9j1zt9vhQ1nlfMykpCqNjYyTicSYmJiLgX1Ke5mGRfIvSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d13115b7146bc2c449f8494ff6aff85f/e4706/1440p_Settings_Multimedia_Image_Advanced.avif 230w", "/en/static/d13115b7146bc2c449f8494ff6aff85f/d1af7/1440p_Settings_Multimedia_Image_Advanced.avif 460w", "/en/static/d13115b7146bc2c449f8494ff6aff85f/7f308/1440p_Settings_Multimedia_Image_Advanced.avif 920w", "/en/static/d13115b7146bc2c449f8494ff6aff85f/2cb71/1440p_Settings_Multimedia_Image_Advanced.avif 1065w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d13115b7146bc2c449f8494ff6aff85f/a0b58/1440p_Settings_Multimedia_Image_Advanced.webp 230w", "/en/static/d13115b7146bc2c449f8494ff6aff85f/bc10c/1440p_Settings_Multimedia_Image_Advanced.webp 460w", "/en/static/d13115b7146bc2c449f8494ff6aff85f/966d8/1440p_Settings_Multimedia_Image_Advanced.webp 920w", "/en/static/d13115b7146bc2c449f8494ff6aff85f/fa9eb/1440p_Settings_Multimedia_Image_Advanced.webp 1065w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d13115b7146bc2c449f8494ff6aff85f/81c8e/1440p_Settings_Multimedia_Image_Advanced.png 230w", "/en/static/d13115b7146bc2c449f8494ff6aff85f/08a84/1440p_Settings_Multimedia_Image_Advanced.png 460w", "/en/static/d13115b7146bc2c449f8494ff6aff85f/c0255/1440p_Settings_Multimedia_Image_Advanced.png 920w", "/en/static/d13115b7146bc2c449f8494ff6aff85f/aa61c/1440p_Settings_Multimedia_Image_Advanced.png 1065w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d13115b7146bc2c449f8494ff6aff85f/c0255/1440p_Settings_Multimedia_Image_Advanced.png",
              "alt": "Web User Interface - 1440p Series - Multimedia Image",
              "title": "Web User Interface - 1440p Series - Multimedia Image",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ImageAdvancedTable mdxType="ImageAdvancedTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      